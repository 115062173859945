jQuery(document).ready(function () {

	jQuery('.js-req-learnupon-access').on('click', function (e) {
		e.preventDefault();
		const originalText = jQuery(this).text();
		const message$ = jQuery('.js-learnupon-access-message');
		let button$ = jQuery(this);
		message$.addClass('d-none');
		button$.attr('disabled', true).text('Requesting access...');

		jQuery.ajax({
			url: learnupon_ajax.ajax_url,
			method: 'post',
			data: {
				'action': learnupon_ajax.action_name,
				'nonce': jQuery(this).data('nonce'),
				'redirect_uri': jQuery(this).data('redirect-uri'),
			},
			success: function (response) {
				if (typeof response.data !== 'undefined' && typeof response.data.url_redirect !== 'undefined') {
					button$.text('Success, redirecting...');
					window.location.href = response.data.url_redirect;
				} else {
					showError('Error getting LearnUpon access URL. Please try again later. If the problem persists, please contact us.');
				}
			},
			error: function (response) {
				response = response.responseJSON;
				showError(response.data.message);
			}
		});

		function showError(message) {
			button$.attr('disabled', false).text(originalText);
			message$.removeClass('d-none').text(message);
		}
	});
});
